<template>
	<div class="business-container">
		<!-- <div class="business-box"></div> -->
		<div id="iframe-container">
            <span>
				{{$t('basic.guide')}} emdata2021@163.com
			</span>
        </div>
	</div>
</template>

<script>
export default {
  metaInfo: {
    title: 'emData 商务',
    meta:
			[
			  {
			    name: 'emData 商务合作',
			    content: 'emData 商务合作'
			  },
			  {
			    name: '鹦鹉塔商务合作',
			    content: '鹦鹉塔商务合作'
			  },
			  {
			    name: 'emData 联系我们',
			    content: 'emData 联系我们'
			  }
			],
    link:
			[
			  {
			    rel: 'emData 商务合作',
			    href: 'https://www.emdataer.com/business'
			  }
			]
  },
  name: 'business',
  data () {
    return {

    }
  },
  mounted () {
    // function changeMobsfIframe(){
    //     const iframe = document.getElementById('iframe');
    //     const deviceWidth = document.body.clientWidth;
    //     const deviceHeight = document.body.clientHeight;
    //     iframe.style.width = (Number(deviceWidth)-240) + 'px'; //数字是页面布局宽度差值
    //     iframe.style.height = (Number(deviceHeight)-64) + 'px'; //数字是页面布局高度差
    // }

    // changeMobsfIframe()

    // window.onresize = function(){
    //     changeMobsfIframe()
    // }

  }
}
</script>

<style scoped lang="less">
    .trend-container2 {
  position: relative;
  width: 100%;
  min-height: 700px;
  padding-bottom: 16px;
}
	.business-container{
		width: 100%;
		height: calc(100vh - 60px);
		padding: 50px;
		box-sizing: border-box;
		.business-box{
			width: 80%;
			height:calc(100% - 100px);
			margin: auto;
			// background-image: url(../../assets/officialwebsite/business.jpg);
			background-size:100% 100%;
			background-repeat:no-repeat;
		}
	}
</style>
